<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import { computed, onMounted, reactive, useContext } from "vue";
import { cosmicService } from "./services/cosmic";
export default {
  name: "App",
  components: {},
  setup() {
    onMounted(async () => {
      await cosmicService.getHomePageContent();
      await cosmicService.getServices();
    });
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
