import { createRouter, createWebHistory } from "vue-router";

function loadPage(page) {
  return () => import(`./pages/${page}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: loadPage("Home"),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
