import { AppState } from "../AppState";

const Cosmic = require("cosmicjs");
const api = Cosmic();
const bucket = api.bucket({
  slug: "eea590e0-9b11-11ec-b962-45fbc640939b",
  read_key: "vzNUd0cBQOYlgiL6rPPPsQwE7GRyIsGo3HyzCZ8MVdAqv4rfvd",
});

class CosmicService {
  async getHomePageContent() {
    const data = await bucket.getObject({
      id: "6220f71c4397950009d5c1e6",
      props: "metadata",
      show_metafields: true,
    });
    AppState.homepage = data.object.metadata;
  }
  async getServices() {
    const data = await bucket.getObjects({
      query: {
        type: "services",
      },
      props: "metadata",
      limit: 6,
    });
    AppState.services = data.objects;
  }
}

export const cosmicService = new CosmicService();
